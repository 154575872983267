import {
  Benefit,
  Faq,
  Footer,
  History,
  App,
  Solution,
  Sticker,
} from "components/home";
import HomeHeader from "components/widgets/home-header";
import * as React from "react";

interface HomeProps {}

export default function Home(props: HomeProps) {
  return (
    <>
      <HomeHeader />
      <section id="home">
        <History />
      </section>
      <section id="benefit">
        <Benefit />
      </section>
      <section id="product">
        <Sticker />
        <App />
      </section>
      <Solution />
      <Faq />
      {/* <Sclist /> */}
      <Footer />

      <style global jsx>{`
        * {
          font-family: "Nunito", "Inter", "Segoe UI",
            "Segoe UI Web (West European)", "Segoe UI", "-apple-system",
            "BlinkMacSystemFont", "Roboto", "Helvetica Neue", "sans-serif";
        }
        html {
          scroll-behavior: smooth;
          scroll-padding-top: 80px;
        }
        .react-multi-carousel-dot button {
          background: #a1a1a1;
          border: none;
        }
        .react-multi-carousel-dot--active button {
          background: #0477ff;
        }
      `}</style>
    </>
  );
}
