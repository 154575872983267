export default {
  transparent: "transparent",
  primary: "#CFE0E9",
  primaryGray: "#A1A1A1",
  defaultText: "#25282B",
  error: "#D93025",
  success: "#32A54A",
  warning: "#FF9A01",
  label: "#868E96",
  info: "#636363",
  title: "#25282B",
  backgroundColor: "#FFFFFF",
  border: "#CED4DA",
  disabled: "#FC404150",
  badge: "#C61B40",
  infoBox: "#E6F7FF",
  placeholder: "#767676",
  textInputBackground: "#F6F6F6",
  loading: "#E5E5E5",
  verified: "#71AEC5",

  black: "#292d2f",
  black50: "#25282B50",
  white: "#FFFFFF",

  neutral2: "#424242",
  neutral3: "#999999",
  neutral4: "#D8D8D8",
  neutral5: "#F2F2F2",
  neutral6: "#FBFBFB",

  lightningYellow: "#FFA931",
  lightningYellow2: "#FFBA5A",
  lightningYellow3: "#FFE9CB",

  radicalRed1: "#FC4041",
  radicalRed2: "#FA5A5A",
  radicalRed3: "#FFF9F9",
  radicalRed4: "#FC404104",

  pigmentGreen: "#059D4C80",
  yellow95: "#FFB900F2",
  cornFlower: "#4A90E2",

  lightGray1: "#D4D4D4",
  whisperGray1: "#E5E5E5",
  darkGray1: "#A7A7A7",
  darkGray2: "#707070",
  nobel1: "#A1A1A1",

  separator: "#F1F1F1",
  editBlue: "#74AEC5",

  blue1: "#2F80ED",
  blue3: "#56CCF2",
  blueHover: "#F4F9FE",
  blueShadow: "#2C63AD",
  bluePrimary: "#0083c9",
  grayBlue: "#CFE0E9",
  lightBlue: "#E8F5FC",
  lightBlue2: "#F0FAFF",

  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F2F2F2",
  grayAvatar: "#D3D3D3",
  grayHover: "#F7F9FA",

  red1: "#EB5757",
  redShadow: "#AA4D4D",
  lightRed: "#FFF5F5",

  purple1: "#9B51E0",
  purple2: "#BB6BD9",

  green2: "#27AE60",
  green3: "#6FCF97",
  lightGreen2: "#F0FBF5",
  greenShadow: "#2D7A4E",
  lightGreen: "#E5FDEF",

  yellow1: "#F2C94C",

  orange: "#F2994A",
  lightOrange: "#FFF1E5",

  backgroundLightRed: "#FFF5F5",
  backgroundLightBlue: "#F4F9FC",
  backgroundLightBlue1: "#E8F5FC",
  backgroundLightPurple: "#F9EBFF",
  backgroundLightGreen: "#E5FDEF",
  backgroundLightYellow: "#FFF4D2",
  pdfColor: "#E5242A",
  xlsColor: "#01733B",
};
